import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Contact Us" />
    <div id="contact" className="container">
      <section id="bring-your-idea" className="row">
        <div className="col-xs-12 col-sm-12 col-md-offset-1 col-md-2">
          <p>Lets build amazing things together.</p>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-9">
          <div className="clipped clipped-bring-your-idea">
            <h1>Bring your idea.</h1>
          </div>
        </div>
      </section>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-offset-2 col-md-5">
          <nav className="social-links">
            <li><a href="https://www.facebook.com/craftersinnovationstudio" target="_blank" rel="noopener noreferrer"><span className="fab fa-facebook-f"></span> Crafters Innovation Studio</a></li>
            <li><a href="https://twitter.com/Crafterstudio" target="_blank" rel="noopener noreferrer"><span className="fab fa-twitter"></span> @Crafterstudio</a></li>
            <li><a href="https://www.youtube.com/channel/UCb8UjTMq_f6ITFwGsWXixLw" target="_blank" rel="noopener noreferrer"><span className="fab fa-youtube"></span> Crafters Youtube Channel</a></li>
          </nav>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-3">
          <address>
            Rua Pedro Furlan, 232
        <span id="contact-city">Sta Bárbara D'Oeste/SP - Brazil</span>
          </address>

          <p>
            <a id="contact-email" href="mailto:contact@crafters.cc">contact@crafters.cc</a>
        +55 19 3628-2077
      </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default Contact
